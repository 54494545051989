<template>
  <div class="sms-config" v-loading="loading">
    <el-form size="medium" label-width="180px" label-position="right" @submit.native.prevent>
      <el-form-item label="">
        <el-radio-group v-model="config.check">
          <el-radio :label="1">阿里云短信</el-radio>
          <el-radio :label="0">腾讯云短信</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="签名名称：">
        <el-input v-model="config[config.check ? 'ali_cloud':'tencent_cloud'].sign_name"></el-input>
      </el-form-item>
      <el-form-item label="模板ID：">
        <el-input v-model="config[config.check ? 'ali_cloud':'tencent_cloud'].template_code"></el-input>
      </el-form-item>
      <el-form-item label="应用ID（SecretId）：">
        <el-input v-model="config[config.check ? 'ali_cloud':'tencent_cloud'].access_key_id"></el-input>
      </el-form-item>
      <el-form-item label="应用秘钥（SecretKey）：">
        <el-input v-model="config[config.check ? 'ali_cloud':'tencent_cloud'].access_key_secret"></el-input>
      </el-form-item>
      <el-form-item label="APPID：" v-if="!config.check">
        <el-input v-model="config[config.check ? 'ali_cloud':'tencent_cloud'].appid"></el-input>
      </el-form-item>
    </el-form>
    <FixedActionBar>
      <el-button type="primary" @click="saveConfig">保存</el-button>
      <!-- <el-button>取消</el-button> -->
    </FixedActionBar>
  </div>
</template>

<script>
import FixedActionBar from "@/base/layout/FixedActionBar";
export default {
  components: { FixedActionBar },
  data() {
    return {
      loading: false,
      config: {
        ali_cloud: {},
        tencent_cloud: {},
      },
    };
  },
  created() {
    this.$request("/admin/admin/Settings/getAdminSmsConfig")
      .then((res) => {
        this.config = res.data;
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
      });
  },
  methods: {
    saveConfig() {
      this.loading = true;
      this.$request("/admin/admin/Settings/setAdminSmsConfig", {
        config: this.config,
      })
        .then((res) => {
          this.$message.success(res.msg);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.sms-config {
  .el-input {
    max-width: 420px;
  }
}
</style>
